.info-item-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}

.info-item-container {
    h3, p {
        color: #575757;
    }
}

.info-item-container h3 {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

.link {
    font-weight: 500;
}

.link:hover {
    color: #95b3a4;
}