//CONTAINER

.text-section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

//

.text-wrapper-1, .text-wrapper-2 {
    display: flex;
    align-items: center;
    border: 3px solid #BFDBCD;
    border-radius: 20px;
    width: 33rem;
    position: relative;
}

.text-wrapper-1 {
    width: 33rem;
    //height: 20rem;
}

.text-wrapper-1-inner {
    margin: 50px;
    width: 80%;
}

.text-wrapper-1-inner div:nth-child(1) p {
    color: #BFDBCD !important;
    font-size: 2rem !important;
    line-height: 2.3rem;
    font-weight: 600;
    margin-bottom: 15px;
}

.text-wrapper-1-inner strong, .text-wrapper-2-inner strong {
    color: #575757 !important;
    font-size: 1rem !important;
    font-weight: 600;
}

.text-wrapper-1-inner p, .text-wrapper-2-inner p {
    font-size: 1rem;
    font-weight: 500;
}

.text-green div:nth-child(1) p {
    text-align: start;
}

.text-wrapper-1-inner div:nth-child(2) p {
    text-align: justify;
}

.text-wrapper-2 {
    height: 100%;
    bottom: 150px;
    left: 30rem;
    margin-top: 50px;
}

.text-wrapper-2-inner {
    margin: 50px;
}

.text-wrapper-2-inner div:nth-child(1) p {
    color: #575757 !important;
    font-size: 2rem !important;
    line-height: 2.3rem;
    font-weight: 600;
}

@media screen and (max-width: 1095px) {
    .general-service-text-section {
        display: flex;
        justify-content: center;
    }
    
    .text-section-content {
        height: 100%;
    }

    .text-wrapper-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        right: 20%;
        position: relative;
    }

    .text-wrapper-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 12rem;
        width: 100%;
        left: auto;
        position: inherit;
        height: 100%;
        position: relative;
        height: 100%;
        left: 23%;
        bottom: 85px;
    }
}

@media screen and (max-width: 720px) {
    .text-wrapper-1-inner div:nth-child(1) p {
        font-family: "Montserrat", sans-serif !important;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.3rem;
    }

    .text-section-content {
        width: 90%;
        padding-bottom: 50px;
    }

    .text-wrapper-1 {
        right: auto;
        position: inherit;
    }

    .text-wrapper-2 {
        left: auto;
        bottom: auto;
        position: inherit;
    }
}

//BUTTON
.text-section-button-wrapper {
    margin-top: 3rem;
}

.text-section-button {
    color: #49625F;
    background-color: #BFDBCD;
    height: 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px;
    border: inherit;
}


//