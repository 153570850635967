.accordion-sidebar {
    padding: 2px 5px;
}

.main-menu > ul > li > a {
    text-transform: none !important;
}

.side-menu > li > a {
    text-transform: none !important;
}