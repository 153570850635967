.divided-section-inner-container {
    justify-content: space-around;
}

.divided-section-col1 h2 {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    color: #575757;
    font-weight: 600;
}

.divided-section-col2 {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.subtitle {
    color: #95b3a4;
    font-weight: 500;
    font-size: 1.2rem;
}

.divided-section-col2 span {
    color: #575757;
    font-weight: 600;
    font-size: 1rem;
}

.about-me-text p {
    padding-bottom: 10px;
}

.text-grey strong {
    color: #575757 !important;
    font-size: 1rem !important;
}