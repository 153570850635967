.steps-pregnant .dotted {
    height: 23rem
}

@media screen and (max-width: 805px) {
    .steps-pregnant .dotted {
        height: 23rem
    }
}

@media screen and (max-width: 780px) {
    .steps-pregnant .dotted {
        height: 26rem
    }
}

@media screen and (max-width: 450px) {
    .steps-pregnant .dotted {
        height: 36rem
    }
}

@media screen and (max-width: 320px) {
    .steps-pregnant .dotted {
        height: 55em
    }
}