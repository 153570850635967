.header-button {
    background-color: #BFDBCD;
    color: #ffffff;
    line-height: 15px;
}

@media screen and (max-width: 1000px) {
    .header-button {
        display: none;
        line-height: 15px;
    }
}