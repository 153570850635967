// Info Section
.facts-section {
    padding-bottom: 0px !important;
}

.factA {
    display: flex;
    justify-content: flex-end; 
    padding-right: '40px'
}

.factB {
    display: flex;
    justify-content: flex-start; 
    padding-left: '40px'
}

//FACTS IN CIRCLE

.info-fact-border {
    display: flex;
    justify-content: center;
    border: 1px solid #B4B4B3;
    border-radius: 50%;
    height: 200px;
    width: 200px;
}

.info-fact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    font-family: 'Montserrat', sans-serif;
    color: #49625F;
}

.info-fact h3 {
    font-size: 3rem;
    font-weight: 600;
    color: #49625F;
}

.info-fact p {
    font-size: 0.8rem;
    text-align: center;
    color: #49625F;
    font-weight: 500;
}


//SEMI CIRCLE + CONTENT
.bottom-info-bg {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 270px;
    background-color: #F1F1F3;
    clip-path: circle(21rem at 50% 23.5rem);
}

.bottom-info {
    width: 24rem;
    height: 150px;
    text-align: center;
    width: 24rem;
    height: 80%;
    display: flex;
    align-items: center;
}

.bottom-info-text p strong {
    color: #575757 !important;
    font-size: 0.9rem !important;
    line-height: 1px;
}

.bottom-info p {
    margin: 20px;
}

.facts-section-title h5 {
    width: 90%;
    font-size: 1.5rem;
    text-align: center;
}


@media screen and (max-width: 1000px) {
  .factA, .factB {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
}

@media screen and (max-width: 780px) {
    .facts-section-title h5 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 300px) {
    .bottom-info-bg {
        height: 300px;
    }

    .bottom-info {
        height: auto;
    }
}