.cookies-wrapper {
    display: flex;
    position: fixed;
    align-items: center;
    bottom: 0;
    background-color: #567267;
    z-index: 1;
    margin: 0px 150px;
    padding: 20px;
    box-shadow: 1px 1px 20px 6px rgb(168 165 165);
}

.cookies-wrapper p {
    color: #ffffff;
}

.cookies-link {
    margin-left: 5px;
    text-decoration: underline;
}

.cookies-link:hover {
    color: #88f1d5;
}

.cookies-wrapper button {
    padding: 0px 10px;
}

@media screen and (max-width: 780px) {
    .cookies-wrapper {
        margin: auto;
    }
}