.text-left {
    align-items: flex-start;
}

.text-center {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.title-section h2, h5 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
}
/* 
.title-section h2 {
   
}

.title-section h5 {
    
} */

//MAIN TITLE

.main-title h2 {
    color: #BFDBCD;
    font-size: 1.3rem !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
    line-height: 1;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.main-title h5 {
    font-size: 2.5rem;
    line-height: 1 !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #575757;
}

//SECONDARY TITLE
.secondary-title h2 {
    font-size: 1.3rem !important;
    color: #BFDBCD;
    line-height: 1 !important;
    margin-bottom: 0px;
    font-weight: 600 !important;
}

.secondary-title h5 {
    font-size: 2rem !important;
    line-height: 1 !important;
    color: #575757;
}

@media screen and (max-width: '780px') {
    .main-title h2 {
        font-size: 1.4rem !important;
        text-align: center !important;
    }

    .main-title h5 {
        font-size: 2rem !important;
        text-align: center !important;
    }

    .secondary-title h2 {
        font-size: 1.3rem !important;
        text-align: center !important;
    }

    .secondary-title h5 {
        font-size: 1.7rem !important;
        text-align: center !important;
    }
}