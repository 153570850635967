@import '~bootstrap/scss/bootstrap';
@import "./assets/scss/helper";
@import "./assets/scss/style";


* {
    color: #575757;
}

strong {
    line-height: 1.6rem !important;
}