.policy-wrapper {
    margin-bottom: 20px;
}

.policy-content {
    display: flex;
    flex-direction: row;
}

.policy-checkbox {
    width: auto !important;
    margin-bottom: 0px !important;
    margin-right: 10px;
}

.policy-link {
    margin-left: 5px;
    text-decoration: underline;
}

.policy-link:hover {
    color: #95B3A4;
}

.policy-wrapper button {
    padding: 0px 10px;
}

@media screen and (max-width: 780px) {
    .policy-wrapper {
        margin: auto;
    }
}