.section5-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.priceItemTwo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
    background-color: #FFFFFF;
    margin: 20px 0px;
    text-align: center;
    padding: 35px 25px;
}

.price-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.priceItemTwo-wrapper span {
    color: #575757;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    margin: 0px;
}

.heading {
    color: #575757;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0px;
}

.item-price h3 {
    margin: 0px;
}

.priceItemTwo-wrapper h1, h3 {
    color: #575757;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0px;
}

.priceItemTwo-wrapper h2 {
    color: #BFDBCD !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.priceItemTwo-wrapper h3 {
    color: #575757;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
}

.priceItemTwo-wrapper p {
    color: #575757;
    margin-bottom: 0px;
    text-align: center;
}

.item-price {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.price-detail {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.price-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 720px) {

    .priceItemTwo-wrapper h1 {
        font-size: 1.3rem;
        font-weight: 600;
    }
    
    .priceItemTwo-wrapper h2 {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 990px) {
    .priceItemTwo-wrapper {
        max-width: 500px;
    }

}


//Button

.button-green {
    background-color: #BFDBCD;
    border: 1px;
    width: 80%;
    margin-top: 20px;
    color: #FFFFFF;
    border: inherit;
}

.button-white {
    background-color: #FFFFFF;
    border: 1px;
    width: 50%;
    margin-top: 20px;
    color: #BFDBCD;
    border: inherit;
}

