.faqs-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faqs-section-title {
    text-transform: capitalize;
}

.faq-question button {
    color: #575757;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.accordion-body {
    display: flex;
}

.accordion-button:not(.collapsed) {
    background-color:  #FFF !important;
    color: #BFDBCD !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b0ddc2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion .accordion-button:focus {
    box-shadow: none !important; /* Set your desired border color */
}

.accordion-body {
    font-size: 1rem;
}

.accordion-body a:hover {
    color: #5b9879;
}

.faq-answer p, strong {
    color: #575757;
}

.faq-answer strong {
    color: #575757 !important;
    font-size: 1rem !important;
}

.faq-answer a {
    text-decoration: underline;
}

.faq-answer a:hover {
    color: #5b9879;
}