.contact-info-wrapper {
    margin-bottom: 40px;
}

.contact-info-wrapper h3 {
    color: #575757;
    width: 100%;
}

.contact-info-wrapper:nth-child(1) ul span, li {
    color: #575757;
}

.contact-icon {
    color: #95b3a4;
}
