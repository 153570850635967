.about-banner {
    z-index: -1;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
}

.about-banner-divider {
    justify-content: flex-start;
}

.about-banner-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px !important;
}

.about-banner-title h2 {
    color: #BFDBCD;
    text-transform: uppercase;
    font-size: 1.5rem !important;
    margin-bottom: 0px;
    line-height: 1.1;
}

.about-banner-text {
    width: 50%;
}

.about-banner-title h5 {
    color: #ffffff;
    font-size: 2rem;
} 


.about-banner-text p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
}

@media screen and (max-width: 1250px) {
    .about-banner-quote {
        left: 30%;
    }
}

@media screen and (max-width: 1195px) {
    .about-banner-quote {
        left: 25%;
        width: 16rem;
    }
}

@media screen and (max-width: 750px) {
    .about-banner {
        background-image: url('../../../assets/images/about/aboutme_opacity.svg') !important;
    }

    .about-banner-title {
        align-items: center;
    }

    .about-banner-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .about-banner-text p {
        font-size: 1rem;
    }

    .about-banner-title h2 {
        font-size: 1.5rem !important;
    } 

    .about-banner-title h5 {
        font-size: 2rem !important;
    } 
}