//CONTAINER
.more-info-wrapper {
    display: flex;
    justify-content: center;
}

.text-section-one-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.2rem !important;
}

.text-section-one-content p {
    line-height: 1.6rem;
    text-align: center;
}

//BUTTON
.text-section-one-button {
    color: #95b3a4;
    background-color: #ffffff;
    height: 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px;
    border: inherit;
}


//