// Personal Portfolio Home
.main-slider-content {
    h2, h1, h3, span {
      color: #575757;
    }
}

.home-banner {
    background-size: auto;
    background-position: right;
    z-index: -1;
}

#banner-container {
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    z-index: -1;
}

.home-banner-divider {
    justify-content: flex-start;
}

.banner-text-container {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    width: 70%;

}

.home-banner-title h2 {
    font-size: 1.8rem;
    font-weight: 500;
}

.home-banner-title h5 {
    font-size: 2.5em;
    font-weight: 600;
}

.home-banner-text p {
    font-size: 1.1rem !important;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.home-banner-text strong {
    font-size: 1.1rem !important;
    color: #575757 !important;
}

/* .highlighted-grey-text {
    font-weight: 800;
    font-size: 1.1rem;
    color: #575757;
} */

.call-to-action {
    display: flex;
    flex-direction: column;
}

.call-to-action-buttons {
    display: flex;
    flex-wrap: wrap;
}

.home-banner-button {
    background-color: #bfdbcd;
    color: #ffffff;
    margin: 10px 10px 10px 0px;
    font-size: 0.9rem;
    margin-right: 10px;
    width: auto;
    padding: 20px 15px;
}

.home-banner-button i {
    color: #ffffff;
}

.hashButton {
    border-bottom: none !important;
    color: inherit;
}


@media screen and (max-width: 1000px){
    #banner-container {
        height: 100%;
        padding-top: 30px;
    }

    .banner-content {
        flex-wrap: wrap;
        height: 100%;
    }

    .home-banner-title h2 {
        font-size: 1.5rem;
    }
    
    .home-banner-title h5 {
        font-size: 2.5em;
    }
    
    .banner-text p {
        font-size: 1rem;
        line-height: 2rem;
    }
}

@media screen and (max-width: 975px){
    #banner-container {
        height: 100%;
        padding-top: 30px;
    }

    .home-banner {
        background-image: url('../../../assets/images/hero/2.svg') !important;
    }

    .banner-content {
        flex-wrap: wrap;
        height: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .banner-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title-section {
        align-items: center;
    }
    
    .banner-text p {
        font-size: 1rem;
        line-height: 2rem;
    }

    .call-to-action-buttons {
        flex-direction: column;
    }

    .home-banner-button {
        background-color: #bfdbcd;
        margin: 10px;
        border-radius: 30px;
    }
}

@media screen and (max-width: 780px){
    #banner-container {
        padding: 30px 0px;
        min-height: 440px;
        height: 100%;
        overflow-y: scroll;
    }

    .banner-content {
        flex-wrap: wrap;
        height: 100%;
        align-items: center;
    }

    .banner-text-container {
        width: 100%;
    }

    .title-section {
        align-items: center;
    }

    .banner-text {
        width: 100%;
    }

    .home-banner-title h2 {
        font-size: 1rem;
    }
    
    .home-banner-title h5 {
        font-size: 2.5em;
    }
    
    .banner-text p {
        font-size: 1rem;
        line-height: 2rem;
        font-weight: 200;
    }

/*     .highlighted-grey-text {
        font-weight: 800;
        font-size: 1.1rem;
    } */

}