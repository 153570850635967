//SECTION 4
.steps-title h2{
    color: #575757;
    text-transform: capitalize;
    font-size: 2rem !important;
}

.steps-title h5 {
    color: #BFDBCD;;
    text-transform: uppercase;
    font-size: 1.8rem !important;
}

.steps-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 20px;
}

.steps-items-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.steps-item-inner {
    display: flex;
    align-items: center;
}


.list-item {
    display: flex; 
    align-items: center;
}

.item-text h3 {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 15px;
}

.item-text {
    margin-left: 20px;
    font-size: 1rem;
}

.measurement-img {
    display: flex;
    justify-content: center;
}

.measurement-img img {
    border-radius: 50%;
}

.circle-image {
    width: 70px;
    margin-right: 20px;
}

.dotted {
    width: 70px;
    height: 25rem;
    position: absolute;
    z-index: -1;
}

.dotted-line-img {
    width: 100%;
    height: 85px;
    background-image: url('../../../assets/images/services/dotted-line.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;

} 

@media screen and (max-width: 720px) {
    .steps-title h2{
        font-size: 1.3rem !important;
    }

    .steps-title h5{
        font-size: 1.7rem !important;
    }
}

@media screen and (max-width: 521px) {
    .dotted {
        height: 30rem;
    }
}

@media screen and (max-width: 473px) {
    .dotted {
        height: 37rem;
    }
}

@media screen and (max-width: 415px) {
    .dotted {
        height: 41rem;
    }
}

@media screen and (max-width: 340px) {
    .dotted {
        height: 50rem;
    }
}
  


