.services-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    padding: 20px 0px;
}

#services-banner-title  {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    z-index: 1;
}

#services-banner-title h1 {
    font-size: 2.2rem;
    text-transform: uppercase;
    color: #BFDBCD;
    font-weight: 500;
}

.services-banner-text {
    width: 80%;
    text-align: center;
    z-index: 1;
}

.banner-subtitle {
    margin-bottom: 20px;
}

.banner-subtitle p, p strong {
    font-size: 1.4rem !important;
    line-height: 2rem;
    font-family: 'Montserrat', sans-serif;
    color: #ffffff !important;
    text-align: center;
}

.banner-subtitle p strong {
    font-size: 1.3rem !important;
    font-weight: 600;
}

.banner-text p {
    font-size: 1.2rem;
    color: #ffffff;
}

.banner-text p strong {
    font-size: 1.2rem !important;
    font-weight: 600;
}

.services-banner-divider {
    z-index: 1;
}

@media screen and (max-width: 1000px){
    .banner-content {
        flex-wrap: wrap;
        height: 100%;
    }

    .services-banner-text {
        width: 100%;
    }

    #services-banner-title h1 {
        font-size: 1.6rem;
        text-transform: uppercase;
        color: #BFDBCD;
    }

    .banner-subtitle{
        margin-bottom: 10px;
    }
    
    .banner-subtitle p, p strong {
        font-size: 1.5rem !important;
    }

    .banner-subtitle p strong {
        font-size: 1.5rem !important;
    }

    .banner-text p, p strong {
        font-size: 1.2rem !important;
    }

    .banner-text p strong {
        font-size: 1.2rem !important;
    }

    
}

@media screen and (max-width: 780px){
    #services-banner-title {
        width: 100%;
    }

    #services-banner-title h1 {
        font-size: 1.8rem;
        text-align: center;
        text-transform: uppercase;
        color: #BFDBCD;
    }

    .banner-subtitle{
        margin-bottom: 10px;
    }
    
    .banner-subtitle p, p strong {
        font-size: 1.2rem !important;
        line-height: 1.3rem;
    }

    .banner-subtitle p strong {
        font-size: 1.2rem !important;
    }

    .banner-text p, p strong {
        font-size: 1.2rem !important;
        line-height: 1.3rem;
    }

    .banner-text p strong {
        font-size: 1.2rem !important;
    }
}

@media screen and (max-width: 420px){
    .banner-subtitle{
        margin-bottom: 10px;
    }

    .banner-subtitle p, p strong {
        font-size: 1rem !important;
        line-height: 1.3rem;
    }

    .banner-subtitle p strong {
        font-size: 1rem !important;
    }

    .banner-text p, p strong {
        font-size: 1rem !important;
        line-height: 1.3rem;
    }

    .banner-text p strong {
        font-size: 1rem !important;
    }

    .services-banner-divider div {
        margin: 5px 0px;
    }
}