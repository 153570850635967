// SECTION 3

.home-text-section {
    display: flex;
    justify-content: center;
    background-color: #95b3a4;
}

.home-text-section p {
    color: #FFF;
}
