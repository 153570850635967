.contact-wrapper {
    background-color: #F1F1F3;
}

.contact-container {
    margin-top: 50px;
}

.contact-title h2 {
    text-transform: uppercase;
}

.contact-intro-subtitle h6 {
    color: #575757;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600;
    padding-bottom: 20px;
}

.contact-intro-subtitle a {
    text-decoration: underline;
}

.contact-intro-subtitle a:hover {
    color: #5b9879;
}

.contact-intro {
    padding-bottom: 30px;
}

.contact-intro p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1.5px;
}

.contact-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-info {
    margin-left: 30px;
}

.contact-button {
    background-color: #BFDBCD !important;
    color: #ffffff !important;
    border: inherit !important;
    width: 16rem;
}

.contact-social-icon {
    margin-right: 20px;
}

.icon-outer-wrapper {
    font-size: 2rem;
}

.spinner-overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
    bottom: 395px;
    background-color: black;
    opacity: 0.3;
    border-radius: 5px;
}

@media screen and (max-width: 720px) {
    .contact-intro-subtitle h6 {
      font-size: 1.1rem
    }

    .contact-info {
        margin-left: 0px;
    }
}