.divider-wrapper {
    display: flex;
    justify-content: center;
}

.green-divider {
    background-color: #BFDBCD;
    height: 5px;
    width: 80px;
    border-radius: 10px;
    margin: 20px 0px;
}