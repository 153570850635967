.about-me-home-section {
    background-color: #F1F1F3;
}

.about-me-inner {
    justify-content: space-between;
}

.about-content {
    width: 80%;
    font-family: 'Montserrat', sans-serif;
}

.about-me-title {
    color: #BFDBCD;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.about-content p {
    font-size: 1rem;
}

.about-me-text strong {
    color: #575757 !important;
    font-size: 1rem !important;
}

.free-consultation {
    margin-top: 60px;
    margin-bottom: 5px;
}

.free-consultation-title {
    text-transform: uppercase;
    font-weight: 600;
    color: #575757;
    font-size: 1rem;
}

.about-me-button {
    background-color: #BFDBCD;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 30px;
    //border: inherit;
}

.about-me-button:hover {
    background-color: #8abba3;
}

.about-me-button:hover {
    color: #ffffff !important;
}

.about-me-button i {
    color: #ffffff;
}

.free-consultation p strong {
    color: #575757 !important;
    font-size: 1rem !important;
}

.button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    padding: 20px 0px;
    border-radius: 20px;
    height: 40px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px;
    width: 17rem;
    text-transform: uppercase;
    border: inherit;
}

.button-content:active {
    opacity: 0.8;
}

.button-deco {
    padding-left: 5px;
}

//PHOTO

.photo-profile {
    display: flex;
    justify-content: flex-end;
}

.photo-profile img {
    border-radius: 50%;
    width: 90%;
}

//RESPONSIVE

@media screen and (max-width: 990px) {
    .about-text-wrapper {
        display: flex;
        justify-content: center;
    }
    .about-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .photo-profile {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .photo-profile img {
        width: 400px;
    }
}

.card-body {
    color: yellowgreen;
  }
