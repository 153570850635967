//FORMATION SECTION 1
.formation-text-row {
    justify-content: center;
    margin-bottom: 70px;
}

.formation-text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 60px;
    background-color: #95b3a4;
    border-radius: 30px;
}

.focus-text p {
    color: #ffffff;
    font-size: 1.2rem !important;
}

.focus-text strong {
    font-size: 1.2rem !important;
    line-height: 1.6rem;
}

//FORMATION STUDIES
.formation-studies {
    align-items: center;
}

//COL 1
.formation-studies-col-1 {
    display: flex;
    justify-content: center;
}

.round-image {
    margin-bottom: 30px;
    max-width: 60%;
}

.round-image img {
    border-radius: 50%;
}

@media screen and (max-width: 1000px) {
    .formation-studies-col-1 {
        display: flex;
        justify-content: center;
    }
    
    .round-image img {
        width: 300px;
        margin-bottom: 30px;
    }
}

//COL 2

.formation-studies-col-2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.line-path {
    width: 25px;
    height: 36rem;
    position: absolute;
    z-index: -1;
    padding-top: 10px;
}

.dot {
    padding-right: 10px;
}

.studies {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: flex-start;
}

.studies-item {
    display: flex;
    align-items: flex-start;
    margin: 10px 0px;
}

.studies-year {
    width: 100px;
}

.studies-year p {
    color: #BFDBCD;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
}

.studies-item-text h5 {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
}

.studies-item-text {
    width: 400px;
    font-size: 1rem;
}

.studies-item-text-title {
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #575757;
}


.dotted-column img {
    height: 22rem;
    margin-right: 30px;
}

.about-me-button span {
    color: #ffffff;
}

@media screen and (max-width: 650px ) {
    .studies-item {
        display: flex;
        margin: 10px 0px;
        //flex-wrap: wrap;
    }

    .studies-year {
        margin-bottom: 5px;
    }

    .dotted-column {
        display: flex;
        align-items: center;
    }

    .dotted-column img {
        height: 26rem;
    }
    .studies-item-text {
        width: 100%;
    }

    .line-path {
        height: 37rem;
    }
}

@media screen and (max-width: 515px ) {
    .line-path {
        height: 38rem;
    }
}

@media screen and (max-width: 510px ) {
    .line-path {
        height: 38rem;
    }
}

@media screen and (max-width: 450px ) {
    .line-path {
        height: 43rem;
    }
}

@media screen and (max-width: 415px ) {
    .line-path {
        height: 44rem;
    }
}

@media screen and (max-width: 380px ) {
    .line-path {
        height: 52rem;
    }
}

@media screen and (max-width: 375px ) {
    .line-path {
        height: 52rem;
    }
}

@media screen and (max-width: 316px ) {
    .line-path {
        height: 52rem;
    }
}
//BUTTON
.formation-button {
    background-color: #ffffff;
    color: #49625F;
    border: 1px;
    width: 280px;
    margin-top: 20px;
}