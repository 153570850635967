.contact-social {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.contact-social h3 {
    color: #575757;
}

.icons-wrapper {
    display: flex;
}

.contact-social-icon {
    color: #BFDBCD;
}

.filter-white {
    filter: invert(99%) sepia(5%) saturate(441%) hue-rotate(33deg) brightness(200%) contrast(100%);
}
