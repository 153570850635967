.text-section-four-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.text-section-four-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    padding-bottom: 20px;
}

.text-section-four-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    width: 90%;
    text-align: justify;
}

.text-section-four-text span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.text-grey p {
    padding-bottom: 20px;
    text-align: justify;
}

@media screen and (max-width: 780px) {
    .text-section-four-container {
        padding: 10px;
    }

    .text-section-four-text {
        width: auto;
    }
}