//ITEM
.service-item {
    padding: 20px 20px 0px 20px;
    height: 100%;
}

.service-inner img {
    height: 100px;
}

.service-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    height: 100%;
}

.service-content h4 {
    text-transform: uppercase;
    color: #575757;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    height: 30px;
}

.service-content p {
    color: #b7bbbd;
    font-size: 1rem;
    text-align: center;
    padding-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

//BUTTON
.services-home-button {
    background-color: #BFDBCD;
    color: #FFF;
    width: 200px;
    box-shadow: none;
    border: inherit;
    margin-top: 20px;
}

.services-home-button:active {
    opacity: 0.8;
}

@media screen and (max-width: 1196px) {
    .services-home-button {
        width: 13rem;
    }

    .service-content h4 {
        height: 50px;
    }
}

@media screen and (max-width: 973px) {
    .service-content h4 {
        height: 70px;
    }
}

@media screen and (max-width: 750px) {
    .services-home-button {
        width: 15rem;
    }

    .service-content h4 {
        height: auto;
    }
}