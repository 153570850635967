
.footer-wrapper {
    //background-color: #49625F;
    background-color: #95b3a4;
}

.footer-logo {
    margin-top: 0px;
}

.footer-logo img {
    width: 10rem;
}


//SECTION 1
.section1-item {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    margin: 5px 0px;
    font-size: 0.7rem;;
}

.section1-item p {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.footer-icon {
    //color: #BFDBCD;
    color: #fff;
}

.footer-social-icon {
    margin-right: 10px;
    margin-top: 10px;
    color: #ffffff;
}

.filter-white {
    filter: invert(99%) sepia(5%) saturate(441%) hue-rotate(33deg) brightness(200%) contrast(100%);
}


//SECTION 2-3

.footer-nav-section-wrapper {
    height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-sections {
    display: flex;
    flex-direction: column;
}

.footer-nav-section-wrapper h6 {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.footer-nav-list {
    display: flex;
    flex-direction: column;
}

.footer-nav-list ul {
    & > li {
        margin-top: 10px;
        margin-bottom: 10px;

        & > a {
            display: block;
            font-size: 1rem;
            line-height: 27px;
            font-weight: 400;
            letter-spacing: 0.5px;
            color: #ffffff;
            position: relative;
            padding-right: 24px;
            text-transform: capitalize;

        }

        &.current,
        &.active,
        &:hover {
            & > a {
                color: #F1F1F3;
                font-weight: 500;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}


//DIVIDER + COPYRIGHT
.footer-icon-wrapper {
    font-size: 1.2rem;
}

.align-items {
    display: flex;
    margin: 0px 50px;
    justify-content: center;
}

.footer-credits-wrapper {
    display: flex;
}

.footer-copyright-wrapper p, .footer-credits-wrapper p {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
}

.footer-credits-wrapper a {
    color: #ffffff;
    text-decoration: underline;
}

.footer-divider {
    color: #BFDBCD;
    border: 0.5px solid #F1F1F3; 
    margin-bottom: 20px;
    width: 100%;
}

@media screen and (max-width: 902px) {
    .align-items {
        flex-wrap: wrap;
    }

    .footer-copyright-wrapper {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 750px) {
    .footer-logo {
        width: 10rem;
        margin-top: 0px;
    }

    .align-items {
        flex-wrap: wrap;
    }

    .footer-copyright-wrapper {
        margin-bottom: 10px;
    }
}