.float {
    position:fixed;
	width:60px;
	height:60px;
	bottom:100px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
    z-index: 1;
}

.my-float {
    margin-top: 10px;
    color: #FFF;
    font-size: 40px !important;
}