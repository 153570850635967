.prices-wrapper {
    background-color: #F1F1F3 !important;
    padding-top: 80px !important;
    justify-content: space-between;
}

.price-outer-wrapper {
    height: 100%;
    width: 100%;
}

//TITLE

.prices-title {
    padding-bottom: 10px;
}

.prices-title h2 {
    text-transform: uppercase;
}

.prices-title h5 {
    text-transform: capitalize;
}

//DIVIDER

.prices-divider .green-divider{
    margin: 0px 0px 20px 0px;
}

.prices-row-1 {
    justify-content: center;
    padding-bottom: 20px;
}
.prices-row-3 {
    justify-content: center;
    padding-bottom: 20px;
}

//TITLE ROW 2

.title-row-2 h5 {
    text-transform: capitalize;
    font-size: 1.6rem !important;
    color: #575757 !important;
}

.prices-row-2 {
    justify-content: center;
    padding-top: 50px;
}

.price-detail-2 {
    width: 350px;
}

.price-detail-2 div {
    width: 90%;
}

.price-detail-2 span {
    font-size: 1rem;
}

.price-footnote {
    font-size: 0.7rem;
    margin-top: 5px;
    font-weight: 600;
}

.footnote {
    text-align: center;
}

.pregnant-footnote {
    padding-bottom: 10px;
    text-align: center;
}

.payment-method {
    text-align: center;
}

.payment-method p strong, .footnote p strong {
    color: #575757 !important;
    font-size: 1rem !important;
    padding-bottom: 10px;
}