.price-section-wrapper {
    background-color: #F1F1F3;
    height: 100%;
}
.price-section-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.price-list {
    justify-content: center;
}

.price-title h2 {
    text-transform: uppercase;
}

.price-detail-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 350px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
}

.prices-wrapper {
    background-color: #F1F1F3 !important;
    padding-top: 80px !important;
}

.prices-title {
    padding-bottom: 10px;
}

.prices-row-1 {
    justify-content: center;
}

.prices-title h5 {
    text-transform: capitalize;
}

.title-row-2 h5 {
    text-transform: capitalize;
    font-size: 1.8rem !important;
    color: #575757 !important;
}

.prices-row-2 {
    justify-content: center;
    padding-top: 50px;
}

.price-detail-2 {
    
    width: 350px;
}

.price-detail-2 div {
    width: 90%;
}

.price-detail-2 span {
    font-size: 1rem;
}

.extra-info {
    display: flex;
    justify-content: center;
    //padding-left: 100px;
    padding-top: 20px;
    text-align: center;
}

.extra-info p {
    width: 80%;
}

@media screen and (max-width: 780px) {
    .extra-info {
        padding-left: auto;
    }
}