.video-media-youtube-inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .video-media-youtube-inner-video {
    flex: 0 0 60%;
  }
  
  .video-media-youtube-inner-video .sixteen-by-nine {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  
  .video-media-youtube-inner-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
