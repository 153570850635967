//SECTION 3
.general-services-wrapper {
    background-color: #F1F1F3;
}

.col-two-wrapper {
    display: flex;
    justify-content: center;
}

.general-services-title h3 {
    color: #575757;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    line-height: 3rem;
    text-transform: uppercase;
}

.method-text p {
    padding-bottom: 20px;
    text-align: justify;
}

.method-text p, .highlight-text-wrapper p, .highlight-text-wrapper p strong {
    font-size: 1rem;
    color: #575757 !important;
    font-size: 1rem !important;
}

.method-text strong {
    color: #575757 !important;
    font-size: 1rem !important;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5rem;
}

.highlight-text-wrapper {
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
    padding: 20px;
    margin: 20px 0px;
    text-align: justify;
}

@media screen and (max-width: 720px) {
    .general-services-title h3{
        font-size: 1.3rem;
        line-height: 1.5;
    }
    .col-two {
        padding: 0px 10px;
    }
}